import { HttpClient } from "@angular/common/http";
import { Injectable, VERSION } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AppService {
  private deviceInfo: any;
  private isMobile: any;
  private isTablet: any;
  private isDesktopDevice: any;
  constructor(
    private _http: HttpClient,
    private deviceService: DeviceDetectorService
  ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }
  private createUUID() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }

  public getConfigs(): Observable<any> {
    let payload: any = {
      device_make: this.deviceInfo.os,
      device_model: this.deviceInfo.userAgent,
      device_os: this.deviceInfo.os,
      device_os_version: this.deviceInfo.os_version,
      device_build_version: this.deviceInfo.browser_version,
      current_app_version: VERSION.full,
      device_platform: "web_" + this.deviceInfo.deviceType,
      app_center_id: "yms_" + this.createUUID(),
    };

    if (
      localStorage.getItem("yms_session") &&
      localStorage.getItem("yms_session") !== "undefined"
    ) {
      payload.device_id = localStorage.getItem("yms_session");
    }
    if (
      localStorage.getItem("acid_session") &&
      localStorage.getItem("acid_session") !== "undefined"
    ) {
      payload.app_center_id = localStorage.getItem("acid_session");
    }

    return this._http.post(
      `${environment.baseUrl}/config?code=${environment.config_fn_code}`,
      payload
    );
  }
}
