import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { Client } from "@microsoft/microsoft-graph-client";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { Observable } from "rxjs";

import { User } from "../model/user";
import { msalConfigParams, OAuthSettings } from "../oauth";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public authenticated: boolean;
  public user?: User;
  public graphClient?: Client;

  constructor(private msalService: MsalService) {
    const accounts = this.msalService.instance.getAllAccounts();
    this.authenticated = accounts.length > 0;
    if (this.authenticated) {
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
    this.getUser().then((user) => {
      this.user = user;
    });
  }

  async isLoggedIn() {
    let users: any = this.msalService.instance.getAllAccounts();

    if (users.length > 0) {
      this.msalService.instance.setActiveAccount(users[0]);
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
    return this.authenticated;
  }

  // Prompt the user to sign in and
  // grant consent to the requested permission scopes
  async signIn(): Promise<void> {
    const result: any = await this.msalService
      // .loginRedirect(msalConfigParams)
      .loginPopup(OAuthSettings)
      .toPromise()
      .catch((reason) => {
        console.log(reason);
      });

    if (result) {
      this.msalService.instance.setActiveAccount(result.account);
      this.authenticated = true;
      // Temporary placeholder
      this.user = new User();
      this.user.displayName = result.account.name;
      this.user.email = result.account.username;
      this.user.avatar = "/assets/shell/default-profile.svg";

      // Temporary to display token in an error box
      console.log(result.accessToken);
    }
  }

  // Sign out
  async signOut(): Promise<void> {
    window.location.reload();
    this.user = undefined;
    this.authenticated = false;
    await this.msalService.logout().toPromise();
  }

  private async getUser(): Promise<User | undefined> {
    if (!this.authenticated) return undefined;

    // Create an authentication provider for the current user
    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      this.msalService.instance as PublicClientApplication,
      {
        account: this.msalService.instance.getActiveAccount()!,
        scopes: OAuthSettings.scopes,
        interactionType: InteractionType.Popup,
      }
    );

    // Initialize the Graph client
    this.graphClient = Client.initWithMiddleware({
      authProvider: authProvider,
    });

    // Get the user from Graph (GET /me)
    const graphUser: MicrosoftGraph.User = await this.graphClient
      .api("/me")
      .select("displayName,mail")
      .get();

    this.authenticated = true;
    const user = new User();
    user.displayName = graphUser.displayName ?? "";
    // Prefer the mail property, but fall back to userPrincipalName
    user.email = graphUser.mail ?? graphUser.userPrincipalName ?? "";
    user.timeZone = graphUser.mailboxSettings?.timeZone ?? "UTC";

    // Use default avatar
    user.avatar = "/assets/shell/default-profile.svg";

    return user;
  }

  async getUsersList(teams_group_id: string, query: string) {
    // Create an authentication provider for the current user
    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      this.msalService.instance as PublicClientApplication,
      {
        account: this.msalService.instance.getActiveAccount()!,
        scopes: OAuthSettings.scopes,
        interactionType: InteractionType.Popup,
      }
    );

    // Initialize the Graph client
    this.graphClient = Client.initWithMiddleware({
      authProvider: authProvider,
    });
    console.log(query);

    const graphTeams: any = await this.graphClient
      .api(
        "/groups/" +
          teams_group_id +
          "/members" +
          (query != null && query != ""
            ? '?$search="displayName:' + query + '"'
            : "")
      )
      .headers({ ConsistencyLevel: "eventual" })
      // .select('displayName,mail,mailboxSettings,userPrincipalName')
      .get();
    return graphTeams;
  }

  async getTemGroupsList(query: string) {
    // Create an authentication provider for the current user
    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      this.msalService.instance as PublicClientApplication,
      {
        account: this.msalService.instance.getActiveAccount()!,
        scopes: OAuthSettings.scopes,
        interactionType: InteractionType.Popup,
      }
    );

    // Initialize the Graph client
    this.graphClient = Client.initWithMiddleware({
      authProvider: authProvider,
    });

    const graphTeams: any = await this.graphClient
      .api('/groups?$search="displayName:' + query + '"')
      .headers({ ConsistencyLevel: "eventual" })
      // .select('displayName,mail,mailboxSettings,userPrincipalName')
      .get();
    return graphTeams;
  }
}
