import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "dashboard",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./settings/settings.module").then((m) => m.SettingsModule),
  },
  {
    path: "users",
    loadChildren: () =>
      import("./users/users.module").then((m) => m.UsersModule),
  },
  {
    path: "plants",
    loadChildren: () =>
      import("./plants/plants.module").then((m) => m.PlantsModule),
  },
  {
    path: "carriers",
    loadChildren: () =>
      import("./carriers/carriers.module").then((m) => m.CarriersModule),
  },
  {
    path: "suggestions",
    loadChildren: () =>
      import("./suggestions/suggestions.module").then(
        (m) => m.SuggestionsModule
      ),
  },
  {
    path: "devices",
    loadChildren: () =>
      import("./devices/devices.module").then((m) => m.DevicesModule),
  },
  {
    path: "user-logs",
    loadChildren: () =>
      import("./user-logs/user-logs.module").then((m) => m.UserLogsModule),
  },
  {
    path: "tasks",
    loadChildren: () =>
      import("./tasks/tasks.module").then((m) => m.TasksModule),
  },
  // {
  //   path: '**',
  //   redirectTo: '/',
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
