export const OAuthSettings = {
  appId: "5add19fe-930b-4e03-9d51-bf20ccc91543",
  redirectUri: window.location.origin,
  redirectStartPage: window.location.origin,
  scopes: ["user.read"],
};

export const msalConfigParams = {
  clientId: "5add19fe-930b-4e03-9d51-bf20ccc91543",
  authority:
    "https://login.microsoftonline.com/773bda68-87c6-401a-b9a4-3dfb1f92e189",
  redirectUri: window.location.origin,
  scopes: ["User.Read"], // Replace with the desired scope(s) for your API
};
