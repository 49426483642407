import { Component } from "@angular/core";
import { AppStore } from "./app.store";
import { User } from "./auth/model/user";
import { AuthService } from "./auth/service/auth.service";
import { Subscription } from "rxjs";
import { AppService } from "./app.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  public subscription: Subscription = new Subscription();
  title = "YMS";

  // Is a user logged in?
  get authenticated(): boolean {
    return this.authService.authenticated;
  }
  // The user
  get user(): User | undefined {
    return this.authService.user;
  }
  constructor(
    public authService: AuthService,
    public appStore: AppStore,
    public appService: AppService
  ) {
    this.subscription.add(
      this.appService.getConfigs().subscribe((response) => {
        localStorage.setItem("yms_session", response.device._id);
        localStorage.setItem("acid_session", response.device.app_center_id);
        response.settings.COUNTRY_CODES = JSON.parse(
          response.settings.COUNTRY_CODES
        );
        response.settings.FUNCTION_CODES = JSON.parse(
          response.settings.FUNCTION_CODES
        );
        this.appStore.settings = response.settings;
      })
    );
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      console.log(this.authenticated);
      this.signIn();
    }
  }

  async signIn(): Promise<void> {
    await this.authService.signIn();
  }
  async signOut(): Promise<void> {
    await this.authService.signOut();
  }
  ngOnDestroy() {
    /* Unsubscribing the Observable objects */
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
